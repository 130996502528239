import firebase from 'firebase';
export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyCAEagJPkkIM7RiEtWvqd9vSC0Kvb5yS_4",
    authDomain: "fudev-push.firebaseapp.com",
    projectId: "fudev-push",
    storageBucket: "fudev-push.appspot.com",
    messagingSenderId: "946637110717",
    appId: "1:946637110717:web:709203ebf352efafbd66d4",
    measurementId: "G-6C71PWBGN0"
});
}

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('Your token is:', token);
    
    return token;
  } catch (error) {
    console.error(error);
  }
}