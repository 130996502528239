import React, {useState} from 'react';
import { askForPermissionToReceiveNotifications } from "./push-notification";
import logo from './logo.svg';
import './App.css';

function App() {

  const [token, setToken] = useState(null);

  const onSubscribe = () => {
    askForPermissionToReceiveNotifications().then(token => {
      setToken(token);
    });
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <button onClick={onSubscribe} >
          Click to receive notifications
        </button>
        <span>
          {token}
        </span>
      </header>
    </div>
  );
}

export default App;
